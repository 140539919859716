import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const superAdminNavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'hosp-dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'feather icon-home',
        url: '/hospital/hosp-dashboard',
      },
      {
        id: 'hosp-etablissement',
        title: 'Establishment',
        type: 'item',
        icon: 'ng-tns-c95-7 feather icon-activity',
        url: '/hospital/healthcare-facility',
      },
      // {
      //   id: 'medical-staff-type',
      //   title: 'Medical_Staff_type',
      //   type: 'item',
      //   icon: 'ng-tns-c95-6 feather icon-users',
      //   url: '/hospital/medical-staff-type',
      // },
    ],
  },
];

const adminNavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      
      {
        id: 'hosp-dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'feather icon-home',
        url: '/hospital/hosp-dashboard',
      },
      {
        id: 'qr-code',
        title: 'QR Code',
        type: 'item',
        icon: 'ng-tns-c95-6 feather icon-maximize',
        url: '/hospital/qr-code',
      },
      {
        id: 'demandes',
        title: 'Demandes',
        type: 'item',
        icon: 'ng-tns-c95-6 feather icon-bell',
        url: '/hospital/tdeb'
      },
      {
        id: 'hospital',
        title: 'Paramétrage',
        type: 'collapse',
        icon: 'ng-tns-c95-6 feather icon-settings',
        children: [
          {
            id: 'hosp-departement',
            title: 'Services',
            type: 'item',
            icon: 'ng-tns-c95-11 feather icon-book',
            url: '/hospital/departement',
          },
          {
            id: 'medical-staff',
            title: 'Medical_Staff',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-users',
            url: '/hospital/medical-staff',
          },
          {
            id: 'pathway-type',
            title: 'Pathway_type',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-file-minus',
            url: '/hospital/type-pathway',
          },
          {
            id: 'needs-type',
            title: 'Needs_Type',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-bell',
            url: '/hospital/needs-type',
          },
          {
            id: 'phone-numbers',
            title: 'Phone_numbers',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-phone-call',
            url: '/hospital/phone-numbers'
          },
        ]
      },
      {
        id: 'envois',
        title: 'Entrées',
        type: 'collapse',
        icon: 'ng-tns-c95-6 feather icon-log-in',
        children: [
          {
            id: 'invitations',
            title: 'Invitations',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-mail',
            url: '/hospital/invitations'
          },
          {
            id: 'requests',
            title: 'Requests',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-user-plus',
            url: '/hospital/requests'
          },
          /* {
            id: 'tdeb',
            title: 'Tdeb',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-user-plus',
            url: '/hospital/tdeb'
          } */
        ]
      },

      /* {
        id: 'envois',
        title: 'Demandes',
        type: 'collapse',
        icon: 'ng-tns-c95-6 feather icon-bell',
        children: [
          {
            id: 'tdeb',
            title: 'Tdeb',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-user-plus',
            url: '/hospital/tdeb'
          },
          {
            id: 'notifications',
            title: 'notifications',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-bell',
            url: '/hospital/notifications',
          }
          
        ]
      }, */
      
      /*   {
        id: 'type-soin',
        title: 'Treatment_Type',
        type: 'item',
        icon: 'ng-tns-c95-6 feather icon-shield',
        url: '/hospital/type-soin',
      }, */
      {
        id: 'batch',
        title: 'Gestion Batch',
        type: 'collapse',
        icon: 'ng-tns-c95-6 feather icon-box',
        children: [
          {
            id: 'upload',
            title: 'Upload',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-file',
            url: '/hospital/upload'
          },
          {
            id: 'batch',
            title: 'Batch',
            type: 'item',
            icon: 'ng-tns-c95-6 feather icon-settings',
            url: '/hospital/batch'
          }
        ]
      },
      
    ],
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    let oauthUser = JSON.parse(localStorage.getItem('auth-user'));
    if (oauthUser && oauthUser.roles.indexOf('SUPER_ADMIN') !== -1) {
      return superAdminNavigationItems;
    } else {
      return adminNavigationItems;
    }
  }
}